import React, { useState, useCallback } from "react"
import Form from "react-bootstrap/Form"
import Button from "react-bootstrap/Button"
import "../components/login.css"
import Image from "react-bootstrap/Image"
import Container from "react-bootstrap/Container"
import { StaticImage } from "gatsby-plugin-image"
import { navigate } from "gatsby"
import { handleLogin, isLoggedIn } from "../services/auth"

export default function Login() {
  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")

  const handleSubmit = useCallback(
    event => {
      event.preventDefault()
      ;(async () => {
        try {
          console.log(
            `[attempt login] username="${username}" password = "${password}" `
          )
          await handleLogin(username, password)
          navigate("/app/home")
        } catch (e) {
          console.error(e)
        }
        if (isLoggedIn() == false) {
          alert("你的用户名或者密码错误")
        }
      })()
    },
    [username, password]
  )

  if (isLoggedIn()) {
    navigate("/app/profile")
    return null
  }
  return (
    <div className="center">
      {/* <body className = "background"> */}
      <Container className="box">
        <StaticImage
          src="../images/logo.jpeg"
          quality={95}
          formats={["AUTO", "WEBP", "AVIF"]}
          alt="Image Gone"
          className="imagestyle"
        />
        <Form method="post" onSubmit={handleSubmit}>
          <Form.Group controlId="formBasicText">
            <Form.Label>用户名</Form.Label>
            <Form.Control
              type="text"
              placeholder="请输入用户名"
              name="username"
              onChange={event => setUsername(event.target.value)}
            ></Form.Control>
          </Form.Group>

          <Form.Group controlId="formBasicPassword" style={{ left: 100 }}>
            <Form.Label>密码</Form.Label>
            <Form.Control
              type="password"
              placeholder="请输入密码"
              name="password"
              onChange={event => setPassword(event.target.value)}
            ></Form.Control>
          </Form.Group>

          <Form.Group controlId="formBasicCheckbox">
            <Form.Check type="checkbox" label="Check me out" />
          </Form.Group>
          <Button type="submit" class= "button-my">
            登陆
          </Button>
        </Form>
      </Container>
      {/* </body> */}
    </div>
  )
}
