import React, { useEffect } from "react"
import axios from "axios"
import { Router } from "@reach/router"
import PrivateRoute from "../components/privateRoute"
import Login from "../components/login"
import home from "../components/home"
axios.defaults.withCredentials = true
axios.defaults.headers.post["Content-Type"] = "application/json"
const server = "http://127.0.0.1:8000"

export default function App() {
  let interval
  useEffect(() => {
    interval = setInterval(() => {
      console.log("current interval")
    }, 1000)
    return () => clearInterval(interval)
  })

  return (
    <div>
      <Router>
        <PrivateRoute path="/app/home" component={home} />
        <Login path="/app/login" />
      </Router>
    </div>
  )
}
