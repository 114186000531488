import * as React from "react"
import { Link } from "gatsby"
import NavBar from "./navbar"
import "../components/login.css"
import { getUser } from "../services/auth"
import { StaticImage } from "gatsby-plugin-image"
import { Col, Container, Row, Jumbotron, Button } from "react-bootstrap"
class home extends React.Component {
  render() {
    return (
      <span>
        <NavBar />
        <span>
          <Jumbotron>
            <h1>欢迎你, {getUser().username}!</h1>
            <p>这里是你的家园系统，如果需要了解更多的信息，请点击帮助按钮</p>
            <p>
              <Button variant="primary" href="/help">
                帮助
              </Button>
            </p>
          </Jumbotron>
        </span>
        <div className="center">
          <h1>家园系统 </h1>
          <span>
            {/* <Container>
              <Row>
                <Col>
                  {" "}
                  <span>
                    <StaticImage
                      src="../images/logo.jpg"
                      width={1000}
                      quality={100}
                      formats={["AUTO", "WEBP", "AVIF"]}
                      alt="A Gatsby astronaut"
                      style={{ marginBottom: `1.45rem` }}
                    />
                    <Link to="/history" style={{ color: "blue" }}>
                      采集系统
                    </Link>
                  </span>
                </Col>
                <Col>
                  <span>
                    <StaticImage
                      src="../images/logo.jpg"
                      width={1000}
                      quality={100}
                      formats={["AUTO", "WEBP", "AVIF"]}
                      alt="A Gatsby astronaut"
                      style={{ marginBottom: `1.45rem` }}
                    />
                    <Link to="/history" style={{ color: "blue" }}>
                      采集系统
                    </Link>
                  </span>
                </Col>
                <Col>
                  {" "}
                  <StaticImage
                    src="../images/logo.jpg"
                    width={1000}
                    quality={100}
                    formats={["AUTO", "WEBP", "AVIF"]}
                    alt="A Gatsby astronaut"
                    style={{ marginBottom: `1.45rem` }}
                  />
                  <Link to="/history" style={{ color: "blue" }}>
                    采集系统
                  </Link>
                </Col>
              </Row>
            </Container> */}
          </span>
        </div>
      </span>
    )
  }
}

export default home
